import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   students: []
};

export const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        saveStudentsData: (state, action) => {
            state.students = action.payload;
        },
        clearStudentsData: (state) => {
            state.students = [];
        }
    },
});

export const { saveStudentsData, clearStudentsData } = studentsSlice.actions;
export const studentsReducer = studentsSlice.reducer;
