import { configureStore } from "@reduxjs/toolkit";
import { authReducer, authSlice } from "./slices/authSlice";
import { layoutReducer, layoutSlice } from "./slices/layoutSlice";
import { customizerReducer, customizerSlice } from "./slices/customizerSlice";
import { studentsReducer } from "./slices/studentsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        layout: layoutReducer,
        customizer: customizerReducer,
        students: studentsReducer,
    },
})

export const authActions = authSlice.actions;
export const layoutActions = layoutSlice.actions;
export const customizerActions = customizerSlice.actions;
