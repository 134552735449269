import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Loadable from '../layouts/full-layout/loadable/Loadable'
import { billingLoader, firstLoader, orgLoader } from '../util/auth'

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullLayout'))
)
const BlankLayout = Loadable(
  lazy(() => import('../layouts/blank-layout/BlankLayout'))
)
const ErrorLayout = Loadable(
  lazy(() => import('../layouts/error-layout/ErrorLayout'))
)
/* ***End Layouts**** */

const FeedbackPage = Loadable(
  lazy(() => import('../pages/main/feedback/FeedbackPage'))
)
const FeedbackResponders = Loadable(
  lazy(() => import('../pages/main/feedback/FeedbackResponders'))
)
const BranchesPage = Loadable(
  lazy(() => import('../pages/main/branches/BranchesPage'))
)
const GradesPage = Loadable(
  lazy(() => import('../pages/main/grades/GradesPage'))
)
const MembersPage = Loadable(
  lazy(() => import('../pages/main/members/MembersPage'))
)
const StudentsPage = Loadable(
  lazy(() => import('../pages/main/students/StudentsPage'))
)
const StudentsDetailsPage = Loadable(
  lazy(() => import('../pages/main/students/StudentsDetailsPage'))
)
const StudentsRecordsPage = Loadable(
  lazy(() => import('../pages/main/students/StudentsRecordsPage'))
)
const StudentsRecordsDetails = Loadable(
  lazy(() => import('../pages/main/students/StudentRecordDetails'))
)
const ProfilePage = Loadable(
  lazy(() => import('../pages/main/profile/ProfilePage'))
)
const BillingPage = Loadable(
  lazy(() => import('../pages/main/billing/BillingPage'))
)
const HomePage = Loadable(lazy(() => import('../pages/main/home/HomePage')))
const NotificationsPage = Loadable(
  lazy(() => import('../pages/main/home/NotificationsPage'))
)

const AppointemntsPage = Loadable(
  lazy(() => import('../pages/main/appointments/AppointemntsPage'))
)

const MobileAnalyticsPage = Loadable(
  lazy(() => import('../pages/main/moileAnalytics/moileAnalyticsPage'))
)

const Router = [
  {
    path: '',
    // loader: firstLoader,
    element: <BlankLayout />,
    errorElement: <ErrorLayout />,
    children: [
      {
        path: '',
        loader: orgLoader,
        element: <FullLayout />,
        errorElement: <ErrorLayout />,
        children: [
          // { path: '', element: <Navigate to='/home' /> },
          {path: "appointments", exact: true, element: <AppointemntsPage />},
          {path: "mobile-analytics", exact: true, element: <MobileAnalyticsPage />},
          {
            path: 'feedback',
            exact: true,
            element: <FeedbackPage />
          },
          {
            path: 'feedback/:feedbackId',
            element: <FeedbackResponders />
          },
          { path: 'home', exact: true, element: <HomePage /> },
          { path: 'branches', exact: true, element: <BranchesPage /> },
          { path: 'grades', exact: true, element: <GradesPage /> },
          { path: 'members', exact: true, element: <MembersPage /> },
          {
            path: 'billing',
            loader: billingLoader,
            exact: true,
            element: <BillingPage />
          },
          { path: 'students', exact: true, element: <StudentsPage /> },
          {
            path: 'students-details',
            exact: true,
            element: <StudentsDetailsPage />
          },
          {
            path: 'students-records',
            exact: true,
            element: <StudentsRecordsPage />
          },
          {
            path: 'students-records/details/:id',
            exact: true,
            element: <StudentsRecordsDetails />
          },
          { path: 'profile', exact: true, element: <ProfilePage /> },
          {
            path: 'notifications',
            exact: true,
            element: <NotificationsPage />
          },
          // { path: '/tables/basic-table', element: <BasicTable /> },
          // { path: '/tables/pagination-table', element: <PaginationTable /> },
          // { path: '/tables/enhanced-table', element: <EnhancedTable /> },
          // { path: '/tables/collapsible-table', element: <CollapsibleTable /> },
          // { path: '/tables/fixed-header-table', element: <FixedHeaderTable /> },
          // { path: '/form-layouts/form-layouts', element: <FormLayouts /> },
          // { path: '/form-elements/autocomplete', element: <ExAutoComplete /> },
          // { path: '/form-elements/button', element: <ExButton /> },
          // { path: '/form-elements/checkbox', element: <ExCheckbox /> },
          // { path: '/form-elements/date-time', element: <ExDateTime /> },
          // { path: '/form-elements/radio', element: <ExRadio /> },
          // { path: '/form-elements/slider', element: <ExSlider /> },
          // { path: '/form-elements/switch', element: <ExSwitch /> },
          // { path: '/form-layouts/form-wizard', element: <FormWizard /> },
          // { path: '/widgets/widget-feed', element: <WidgetFeed /> },
          // { path: '/widgets/widget-apps', element: <WidgetApps /> },
          // { path: '/charts/line-chart', element: <LineChart /> },
          // { path: '/charts/gredient-chart', element: <GredientChart /> },
          // { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
          // { path: '/charts/area-chart', element: <AreaChart /> },
          // { path: '/charts/column-chart', element: <ColumnChart /> },
          // { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
          // { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
          // { path: '/react-icons', element: <ReactIcons /> },
          // { path: '/form-layouts/form-custom', element: <FormCustom /> },
          // { path: '/treeview', element: <Treeview /> },
          // { path: '/pricing', element: <Pricing /> },
          // { path: '/timeline', element: <CustomTimeline /> },
          // { path: '/typography', element: <CustomTypography /> },
          // { path: '/alert', element: <ExAlert /> },
          { path: '*', element: <Navigate to='/404' /> }
        ]
      },
    ]
  }
]

export default Router
